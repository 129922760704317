import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { THEME_OPTIONS } from '../../constants/theme-options';
import { GlobalHelper } from '../../helper/global.helper';
import { HttpTrackingService } from 'src/app/services/http/httpTracking.service';

@Component({
    selector: 'app-full-width',
    templateUrl: './full-width.component.html',
    styleUrls: ['./full-width.component.css']
})
export class FullWidthComponent implements OnInit, OnDestroy {
  options: any = {};
  isLoadingNewModule = false;
  isRequestPending = false;
  private routerSubscription: Subscription;

  constructor(public globalHelper: GlobalHelper, private router: Router, private trackingService: HttpTrackingService) { }

  ngOnInit() {
    this.options = JSON.parse(JSON.stringify(THEME_OPTIONS));
    this.options.sidebartype = 'overlay';
    const self = this;
    this.routerSubscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        await self.handleNavigationStart();
      }
      if (event instanceof NavigationEnd && self.isRequestPending) {
        self.isLoadingNewModule = false;
      }
    });
  }

  async handleNavigationStart() {
    this.isRequestPending = this.trackingService.isPendingRequestsAvailable();
    if (this.isRequestPending) {
      this.trackingService.resetPendingStatus();
      const shouldLeave = await this.globalHelper.openConfirmationDialog(
        'Are you trying to leave the page?\n\n' +
        'The request has been cancelled. But the data might be saved partially and it leads to data corruption.'
      );
      if (shouldLeave) {
        this.isLoadingNewModule = true;
        this.trackingService.cancelPendingRequests();
      } else {
        this.router.navigate([this.router.url], { skipLocationChange: true });
      }
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
