import { Directive, HostListener, Input  } from '@angular/core';

@Directive({
  selector: '[number-only]'
})

export class NumberOnlyDirective {
  @Input() allowedKeyCodes: any;
  @Input('avoidSecondDot') avoidSecondDot: any;

  inputValue: any;

  @HostListener('ngModelChange', ['$event']) onModelChange(event) {
    // The below conditions are avoid decimal second dot
    if (this.avoidSecondDot) {
      this.inputValue = event;
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    // Not Allowed Keys Condition For 'shift'
    if (e.shiftKey) {
    // not allowed special charcter bcs not allow shift key
      return false;
    }

    // The below conditions are avoid decimal multiple decimal dot
    if (this.avoidSecondDot && typeof this.inputValue === 'string' && this.inputValue.split('.').length > 1
      && [190, 110].indexOf(e.keyCode) !== -1) {
      return false;
    }

    // The below conditions are Allowed Keys
    if ((this.allowedKeyCodes && this.allowedKeyCodes.indexOf(e.keyCode) !== -1) ||  // custom required keys
      // Allow: delete,Backspace,tab,escape,enter
      ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right & space
      (e.keyCode >= 35 && e.keyCode <= 39) || e.keyCode <= 32) {
       // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress
    if ((e.keyCode < 48 || e.keyCode > 57) &&  (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
}
