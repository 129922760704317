import { Injectable } from '@angular/core';

import { PatientService } from '../../../services/patient.service';

@Injectable()
export class PrescriptionService extends PatientService {

    public modelPath = 'patient/prescriptions';
    public presFormDatas: any = {};

    getBodyDatas () {
        return {
            columns: [{
                data: 'pres_id',
                name: 'pres_id',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'pres_date',
                name: 'pres_date',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 0,
                    dir: 'asc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    diagnosisSearch (searchWord) {
        return this.httpService.getMethod(`${this.modelPath}/get_diagnosis?diagnosis=` + searchWord);
    }

    getLatestPrescription (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/latest_patient_prescription`, bodyDatas);
    }

    getNoOfDaysDropdownDatas (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_consultant_no_of_days`, optionParams);
    }

    getPastMCH (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_past_medical_history`, optionParams);
    }

    getIndentConsultantCount (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_indent_consultant_count`, optionParams);
    }

    printHeader () {
        return {};
    }

    printFooter (presPrintData) {
        const { medclinicOrgId } = this.globalHelper.getConstantsValue('app'),
            curOrgId = this.globalHelper.getCurData('curOrgId'),
            content = [];
        let nextVisit;
        if (presPrintData.next_visit) {
            nextVisit = this.globalHelper.getMomentDatas('DD-MMMM-YYYY', presPrintData.next_visit);
        }
        if (`${presPrintData.presPrintSettings.PID}` === '1') {
            content.push([{
                text: `Prescription ID : ${presPrintData.pres_id}`,
                alignment: 'left',
                style: 'footertxt'
            }, {}]);
        }
        if (`${presPrintData.presPrintSettings.PIB}` === '1') {
            content.push([{
                text: `Issued by : ${presPrintData.curUserName}`,
                alignment: 'left',
                style: 'footertxt'
            }, {}]);
        }
        if (`${presPrintData.presPrintSettings.PIA}` === '1') {
            content.push([{
                text: `Issued at : ${presPrintData.curTenantName}`,
                alignment: 'left',
                style: 'footertxt'
            }, {}]);
        }
        content.push([{
            text: `* Next Visit : ${nextVisit || '-'}`,
            margin: [40, 0, 0, 0],
            alignment: 'left',
            style: 'h1'
        }, {
            text: presPrintData.consultantname || '-',
            margin: [0, 0, 40, 0],
            alignment: 'right',
            style: 'h1'
        }]);
        if (curOrgId !== medclinicOrgId) {
            content.push([{
                text: 'Software designed by www.sumanastech.com',
                colSpan: 2,
                alignment: 'center',
                style: 'footertxt'
            }, {}]);
        }
        return {
            layout: 'noBorders',
            table: {
                widths: ['*', '*'],
                body: content
            }
        };
    }

    printStyle () {
        return {
            headerStyle: {
                margin: [40, 0, 40, 0]
            },
            h1: {
                fontSize: 11,
                bold: true,
            },
            h2: {
                fontSize: 9,
                bold: true,
            },
            th: {
                fontSize: 7,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 6,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 10,
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 0, 5, 3]
            },
            footertxt: {
                fontSize: 6,
                margin: [40, 0, 40, 0]
            },
            prescriptionTh : {
                bold: true,
                fontSize: 10,
            },
            fs10: {
                fontSize: 9
            }
        };
    }

    getPMContent (prescription) {
        const { medclinicOrgId } = this.globalHelper.getConstantsValue('app'),
            curOrgId = this.globalHelper.getCurData('curOrgId');
        if (curOrgId === medclinicOrgId) {
            return this.getMedPriContent(prescription);
        }
        return this.getAHPrintContent(prescription);
    }

    getAHPrintContent (prescription) {
        const content = [],
        perPatientInfo = [],
        patientDetails = [],
        perPageItem = [],
        barCodeElemt = document.getElementById(prescription.uhid);
        let bar_image;
        if (prescription.uhid && document.contains(barCodeElemt)) {
            bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
        }
        content.push({
            text: this.globalHelper.getMomentDatas('DD-MMMM-YYYY', prescription.pres_date),
            alignment: 'right'
        });
        content.push({
            layout: 'noBorders',
            table: {
                widths: ['*', '*'],
                body: [
                    [
                        {
                            image : this.imgExport('logo'),
                            width : 10,
                            height : 10,
                            alignment: 'right',
                            margin: [0, 15, 0, 0]
                        }, {
                            image : bar_image,
                            width : 150,
                            height : 50,
                            alignment: 'right'
                        }
                    ]
                ]
            }
        });
        let catgDiv;
        if (prescription.patient_category && prescription.patient_category.patient_short_code &&
            prescription.patient_category.patient_category_color) {
            const catgSC = this.globalHelper.convUC(prescription.patient_category.patient_short_code);
            catgDiv = [{
                canvas: [{
                    type: 'ellipse',
                    x: 8, y: 5,
                    color: prescription.patient_category.patient_category_color,
                    r1: 10, r2: 10
                }]
            }, {
                text: catgSC,
                color: '#ffffff',
                relativePosition: { x: catgSC.length === 1 ? -8 : -11, y: -1 }
            }];
        }
        const vitalDatas = [];
        if ((`${prescription.vitalSettings.BP}` === '1') && prescription.vitalDatas && (prescription.vitalDatas.blood_pressure_systolic || prescription.vitalDatas.blood_pressure_diastolic)) {
            vitalDatas.push(['Blood pressure', ':',
                `${
                    prescription.vitalDatas.blood_pressure_systolic || '-'
                } / ${
                    prescription.vitalDatas.blood_pressure_diastolic || '-'
                } mmHg`
            ]);
        }
        if (`${prescription.vitalSettings.Temperature}` === '1' && prescription.vitalDatas && prescription.vitalDatas.temperature) {
            vitalDatas.push(['Temperature', ':',
                `${
                    prescription.vitalDatas.temperature || '-'
                }°F`
            ]);
        }
        if (`${prescription.vitalSettings.Pulse}` === '1' && prescription.vitalDatas && prescription.vitalDatas.pulse_rate) {
            vitalDatas.push(['Pulse', ':',
                `${
                    prescription.vitalDatas.pulse_rate || '-'
                }Beats`
            ]);
        }
        if (`${prescription.vitalSettings.Weight}` === '1' && prescription.vitalDatas && prescription.vitalDatas.weight) {
            vitalDatas.push(['Weight', ':',
                `${
                    prescription.vitalDatas.weight || '-'
                }Kg`
            ]);
        }
        if (`${prescription.vitalSettings.Height}` === '1' && prescription.vitalDatas && prescription.vitalDatas.height) {
            vitalDatas.push(['Height', ':',
                `${
                    prescription.vitalDatas.height || '-'
                }CM`
            ]);
        }
        if (`${prescription.vitalSettings.SP02}` === '1' && prescription.vitalDatas && prescription.vitalDatas.sp02) {
            vitalDatas.push(['Sp02', ':',
                `${
                    prescription.vitalDatas.sp02 || '-'
                }`
            ]);
        }
        if (`${prescription.vitalSettings.Pain_Score}` === '1' && prescription.vitalDatas && prescription.vitalDatas.pain_score) {
            vitalDatas.push(['Pain', ':',
                `${
                    prescription.vitalDatas.pain_score || '-'
                }`
            ]);
        }
        patientDetails.push([
            {
                border: [false, true, false, true],
                image : this.imgExport(barCodeElemt.getElementsByTagName('img')[0]), // bar_image,//
                width : 30,
                height : 60,
            }, {
                border: [false, true, true, true],
                layout: 'noBorders',
                table: {
                    widths: [65, 5, '*'],
                    body: [
                        [{
                            text: `${this.globalHelper.convUC(prescription.patient_name)}${
                                (prescription.patient_age && prescription.patient_age.age) ?
                                ` / ${prescription.patient_age.age}y` : ''}`,
                            style: ['td', 'h1'],
                            colSpan: 3
                        }, {}, {}],
                        [prescription.uhid].concat(prescription.patient_category ? catgDiv : ['', '']),
                        ['Consultant', ':', {
                            margin: [0, 0, 0, 0],
                            text: this.globalHelper.convUC(prescription.consultantname)
                        }],
                    ]
                }
            }, {
                border: [false, true, false, true],
                margin: [10, 0, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: [ 'auto', 5, 'auto'],
                    body: Array.isArray(vitalDatas) && vitalDatas.length ? vitalDatas : [['', '', '']]
                }
            }
        ]);
        perPatientInfo.push({
            layout: {
                hLineColor: function (i, node) {
                    return '#949494';
                },
                vLineColor: function (i, node) {
                    return '#949494';
                }
            },
            table: {
                headerRows: 1,
                widths: [50, '*', 200],
                body: patientDetails
            }
        });
        content.push(perPatientInfo);
        if (prescription.presPrintSettings && `${prescription.presPrintSettings.SA}` === '1' &&
            prescription.prescription_allergies) {
            content.push({
                text: [
                    { text: 'Allergies :', bold: true},
                    prescription.prescription_allergies || '-'
                ]
            });
        }
        if (prescription.presPrintSettings && `${prescription.presPrintSettings.SD}` === '1') {
            content.push({
                text: [
                    {text: 'Diagnosis :', bold: true},
                    prescription.diag_name || '-'
                ]
            });
        }
        perPageItem.push([{
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Medicine Name',
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('morning'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('afternoon'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('evening'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('night'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: '',
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Days',
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Qty',
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('food'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }]);
        const headerBottom = [];
        for (let i = 0; i < 9 ; i++) {
            const canvasObj = {
                margin: [0, 0, 0, -1],
                border: [false, false, false, true],
                canvas: [{
                    type: 'line', x1: 0, y1: 5, x2: i === 0 ? 70 : 20, y2: 5, lineWidth: 2
                }]
            }, normalObj = {
                margin: [0, 0, 0, 0],
                border: [false, false, false, true],
                text: ''
            };
            headerBottom.push(i === 5 ? normalObj : canvasObj);
        }
        perPageItem.push(headerBottom);
        prescription.prescription_items.map((presData, presIndex) => {
            let remark, prodDetails = [], freqDetails = [], otherDetails = [];
            if (presData.remarks) {
                remark = '\nRemarks : ' + presData.remarks;
            }
            prodDetails = [{
                border: [false, false, false, false],
                text: [{
                    text: presIndex + 1,
                    fontSize: 10
                }, {
                    text: `. ${presData.product_name}\n`,
                    style: 'h2'
                }, {
                    text: `${presData.generic_name ? `(${presData.generic_name})` : ''}`,
                    italics: true,
                    fontSize: 8,
                    color: '#333333'
                }, {
                    text: remark,
                    fontSize: 8,
                    color: '#333333'
                }],
                alignment: 'left',
                style: ['td']
            }];
            if (presData.freqType === 'txt') {
                freqDetails = [{
                    border: [false, false, false, false],
                    text: presData.pres_frequency,
                    alignment: 'center',
                    style: ['td', 'fs10'],
                    colSpan: 4
                }, {}, {}, {}];
            } else {
                freqDetails = [{
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, 0),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, 1),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: `${presData.freqType}` === '3' ? '-' : this.globalHelper.calFrequency(presData.pres_frequency, 2),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, `${presData.freqType}` === '3' ? 2 : 3),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }];
            }
            otherDetails = [{
                border: [false, false, false, false],
                text: (presData.description_name.toUpperCase() === 'SYRUP' ? 'ml' : ''),
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: presData.number_of_days,
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: presData.quantity,
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: (presData.food_type === 'NA' ? '-' : presData.food_type),
                alignment: 'center',
                style: ['td', 'fs10']
            }];
            perPageItem.push(Array.prototype.concat(prodDetails, freqDetails, otherDetails));
        });
        content.push({
            layout: {
                hLineWidth: function(i, node) {
                    return (i === 0) ? 3 : 1;
                }
            },
            table: {
                headerRows: 2,
                widths: ['*', 25, 25, 25, 25, 80, 'auto', 'auto', 25],
                body: perPageItem
            }
        });
        return content;
    }
    getMedPriContent (prescription) {
        const content = [],
        perPatientInfo = [],
        patientDetails = [],
        perPageItem = [],
        barCodeElemt = document.getElementById(prescription.uhid);
        let bar_image;
        if (prescription.uhid && document.contains(barCodeElemt)) {
            bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
        }
        let catgDiv;
        if (prescription.patient_category && prescription.patient_category.patient_short_code &&
            prescription.patient_category.patient_category_color) {
            const catgSC = this.globalHelper.convUC(prescription.patient_category.patient_short_code);
            catgDiv = [{
                canvas: [{
                    type: 'ellipse',
                    x: 8, y: 5,
                    color: prescription.patient_category.patient_category_color,
                    r1: 10, r2: 10
                }]
            }, {
                text: catgSC,
                color: '#ffffff',
                relativePosition: { x: catgSC.length === 1 ? -6 : -6, y: -1 }
            }];
        }
        const vitalDatas = [];
        if ((`${prescription.vitalSettings.BP}` === '1') && prescription.vitalDatas && (prescription.vitalDatas.blood_pressure_systolic || prescription.vitalDatas.blood_pressure_diastolic)) {
            vitalDatas.push({ text: 'BP', bold: true},
                { text: `: ${
                        prescription.vitalDatas.blood_pressure_systolic || '-'
                    } / ${
                        prescription.vitalDatas.blood_pressure_diastolic || '-'
                    } mmHg`
            });
        }
        if (`${prescription.vitalSettings.Temperature}` === '1' && prescription.vitalDatas && prescription.vitalDatas.temperature) {
            vitalDatas.push({ text: 'Temp', bold: true},
                { text:
                `: ${
                    prescription.vitalDatas.temperature || '-'
                } °F`
            });
        }
        if (`${prescription.vitalSettings.Pulse}` === '1' && prescription.vitalDatas && prescription.vitalDatas.pulse_rate) {
            vitalDatas.push({ text: 'Pulse', bold: true},
                { text: `: ${
                    prescription.vitalDatas.pulse_rate || '-'
                } Beats`
            });
        }
        if (`${prescription.vitalSettings.Weight}` === '1' && prescription.vitalDatas && prescription.vitalDatas.weight) {
            vitalDatas.push({ text: 'Weight', bold: true}, { text:
                `: ${
                    prescription.vitalDatas.weight || '-'
                } Kg`
            });
        }
        if (`${prescription.vitalSettings.Height}` === '1' && prescription.vitalDatas && prescription.vitalDatas.height) {
            vitalDatas.push({ text: 'Height', bold: true},
                { text: `: ${
                    prescription.vitalDatas.height || '-'
                } CM`
            });
        }
        if (`${prescription.vitalSettings.SP02}` === '1' && prescription.vitalDatas && prescription.vitalDatas.sp02) {
            vitalDatas.push({ text: 'Sp02', bold: true}, { text:
                `: ${
                    prescription.vitalDatas.sp02 || '-'
                }`
            });
        }
        if (`${prescription.vitalSettings.BMI}` === '1' && prescription.vitalDatas && prescription.vitalDatas.bmi) {
            vitalDatas.push({ text: 'BMI', bold: true}, { text:
                `: ${
                    prescription.vitalDatas.bmi || '-'
                }`
            });
        }
        patientDetails.push(
            [{
                border: [false, true, true, true],
                layout: 'noBorders',
                table: {
                    widths: [65, 0, '*'],
                    body: [
                        [{
                            text: `${this.globalHelper.convUC(prescription.patient_name)}${
                                (prescription.patient_age && prescription.patient_age.age) ?
                                ` / ${prescription.patient_age.age}y` : ''}`,
                            style: ['td', 'h1'],
                            colSpan: 3
                        }, {}, {}],
                        [prescription.uhid].concat(prescription.patient_category ? catgDiv : ['', '']),
                    ]
                }
            }, {
                image : bar_image,
                margin: [0, 10, 0, 0],
                width : 140,
                height : 30,
                alignment: 'right'
            }, {
                margin: [0, 20, 0, 0],
                text: this.globalHelper.getMomentDatas('DD-MMM-YYYY', prescription.pres_date),
                alignment: 'right',
                bold: true
            }
        ]);
        perPatientInfo.push({
            layout: {
                hLineColor: function (i, node) {
                    return '#fff';
                },
                vLineColor: function (i, node) {
                    return '#fff';
                }
            },
            table: {
                headerRows: 1,
                widths: [200, '*', 100],
                body: patientDetails
            }
        });
        content.push(perPatientInfo);
        const treatment = [], vitalDetails = [];
        if (Array.isArray(vitalDatas) && vitalDatas.length) {
            if (vitalDatas.length > 8 ) {
                const rowVDatas1 = [], rowVDatas2 = [];
                vitalDatas.map((vDatas, vIndex) => {
                    if (vIndex < 8) {
                        rowVDatas1.push(vDatas);
                    } else {
                        rowVDatas2.push(vDatas);
                    }
                });
                vitalDetails.push(rowVDatas1);
                const rCnt = 8 - rowVDatas2.length;
                if (rCnt !== 0) {
                    for (let i = 0; i < rCnt; i++) {
                        rowVDatas2.push({text: ''});
                    }
                }
                vitalDetails.push(rowVDatas2);
            } else {
                const rCnt = 8 - vitalDatas.length;
                if (rCnt !== 0) {
                    for (let i = 0; i < rCnt; i++) {
                        vitalDatas.push({text: ''});
                    }
                }
                vitalDetails.push(vitalDatas);
            }
        }
        treatment.push({
            layout: {
                hLineColor: function (i, node) {
                    return (i === node.table.body.length && Array.isArray(vitalDatas) && vitalDatas.length) ? '#949494' : '#000';
                },
                vLineColor: function (i, node) {
                    return '#fff';
                }
            },
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{
                        layout: 'noBorders',
                        table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                { text: 'Allergies :', bold: true },
                                { text: prescription.prescription_allergies || '-' }
                            ],
                            [
                                { text: 'Diagnosis :', bold: true},
                                { text: prescription.diag_name.trim() || '-'}
                            ],
                        ]}
                    }]
                ]
            }
        });
        content.push(treatment);
        if (Array.isArray(vitalDatas) && vitalDatas.length) {
            content.push([{
                layout: {
                    hLineColor: function(i, node) {
                        return (i === node.table.body.length && Array.isArray(vitalDatas) && vitalDatas.length) ? '#000' : '#fff';
                    },
                    vLineColor: function(i, node) {
                        return '#fff';
                    },
                    hLineWidth: function(i, node) {
                        return (i === node.table.body.length && Array.isArray(vitalDatas) && vitalDatas.length) ? 1 : 0;
                    },
                },
                table: {
                    widths: ['*'],
                    margin: [0, 0, 0, 0],
                    body: [
                        [{
                            layout: 'noBorders',
                            margin: [0, 0, 0, 0],
                            table: {
                                widths: [ 'auto', 100, 'auto', 100, 'auto', 100, 'auto', '*'],
                                body: vitalDetails
                            }
                        }]
                    ]
                }
            }]);
        }
        perPageItem.push([{
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Medicine Name',
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('morning'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('afternoon'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('night'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: '',
            style: 'prescriptionTh'
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            image : this.imgExport('food'),
            rowspan: 2,
            width : 12,
            height : 12,
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: '',
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Days',
            style: 'prescriptionTh'
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Qty',
            style: 'prescriptionTh'
        }]);
        const headerBottom = [];
        for (let i = 0; i < 9 ; i++) {
            const canvasObj = {
                margin: [0, 0, 0, -1],
                border: [false, false, false, true],
                canvas: [{
                    type: 'line', x1: 0, y1: 5, x2: i === 0 ? 70 : 20, y2: 5, lineWidth: 2
                }]
            }, normalObj = {
                margin: [0, 0, 0, 0],
                border: [false, false, false, true],
                text: ''
            };
            headerBottom.push((i === 4 || i === 6) ? normalObj : canvasObj);
        }
        perPageItem.push(headerBottom);
        prescription.prescription_items.map((presData, presIndex) => {
            let remark, prodDetails = [], freqDetails = [], otherDetails = [];
            if (presData.remarks) {
                remark = '\nRemarks : ' + presData.remarks;
            }
            prodDetails = [{
                border: [false, false, false, false],
                text: [{
                    text: presIndex + 1,
                    fontSize: 10
                }, {
                    text: `. ${presData.product_name}\n`,
                    style: 'h2'
                }, {
                    text: `${presData.generic_name ? `(${presData.generic_name})` : ''}`,
                    italics: true,
                    fontSize: 8,
                    color: '#333333'
                }, {
                    text: remark,
                    fontSize: 8,
                    color: '#333333'
                }],
                alignment: 'left',
                style: ['td']
            }];
            if (presData.freqType === 'txt') {
                freqDetails = [{
                    border: [false, false, false, false],
                    text: presData.pres_frequency,
                    alignment: 'center',
                    style: ['td', 'fs10'],
                    colSpan: 3
                }, {}, {}, { text: ''}];
            } else {
                freqDetails = [{
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, 0),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, 1),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: this.globalHelper.calFrequency(presData.pres_frequency, `${presData.freqType}` === '3' ? 2 : 3),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }, {
                    border: [false, false, false, false],
                    text: (presData.description_name.toUpperCase() === 'SYRUP' ? 'ml' : ''),
                    alignment: 'center',
                    style: ['td', 'fs10']
                }];
            }
            otherDetails = [{
                border: [false, false, false, false],
                text: (presData.food_type === 'NA' ? '-' : presData.food_type),
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: '',
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: presData.number_of_days,
                alignment: 'center',
                style: ['td', 'fs10']
            }, {
                border: [false, false, false, false],
                text: presData.quantity,
                alignment: 'center',
                style: ['td', 'fs10']
            }];
            perPageItem.push(Array.prototype.concat(prodDetails, freqDetails, otherDetails));
        });
        content.push({
            layout: 'noBorders',
            table: {
                headerRows: 2,
                widths: ['*', 25, 25, 25, 'auto', 25, 25, 'auto', 25],
                body: perPageItem
            }
        });
        return content;
    }

    imgExport(imgID) {
        const img = (typeof imgID === 'string') ? document.getElementById(imgID) : imgID,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
        if (typeof imgID !== 'string') {
            canvas.width = 68;
            canvas.height = 280;
            ctx.translate(72, 75);  // width
            ctx.rotate(Math.PI * 3 / 2);
            ctx.translate(-210, -75); // Height
        } else {
            canvas.width = img['width'];
            canvas.height = img['height'];
        }
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL('image/png');
    }
}
