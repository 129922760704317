import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpTrackingService {
  constructor() {}

  private cancelPendingRequests$ = new Subject<void>();
  private pendingRequests = false;

  /* Cancels all pending Http requests. */
  public cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }

  public onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable();
  }

  /* Handle navigation between modules. */
  isRequestPending() {
    this.pendingRequests = true;
  }

  resetPendingStatus() {
    this.pendingRequests = false;
  }

  isPendingRequestsAvailable(): boolean {
    return this.pendingRequests;
  }
}
