import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    console.error('An error occurred:', error);

    // Ensure Sentry is available globally
    if (typeof Sentry !== 'undefined') {
      Sentry.captureException(error.originalError || error);
    }
  }
}
