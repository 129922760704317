import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotjarService {

  private hotjarInitialized = false;

  constructor() { }

  initHotjar(siteId: number, version: number = 6) {
    if (this.hotjarInitialized) {
      console.warn('Hotjar is already initialized.');
      return;
    }

    this.hotjarInitialized = true;

    // Dynamically load Hotjar script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${siteId}.js?sv=${version}`;

    script.onload = () => {
      console.log('Hotjar script loaded successfully.');
      if (window && window.hj) {
        window.hj('trigger', 'page-view');
      }
    };

    script.onerror = () => {
      console.error('Failed to load Hotjar script. Please check your network or CSP settings.');
    };

    document.head.appendChild(script);
  }
}
