import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { HttpService } from '../http/http.service';
import { GlobalHelper } from '../../helper/global.helper';

import * as XLSX from 'xlsx';

import base64Img from '../../../assets/js/base64-images/base64-img.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class BaseService {

    public modelPath;
    timeout;
    public docDefinition;

    constructor(private router: Router, protected httpService: HttpService, public globalHelper: GlobalHelper,
        public hotkeysService: HotkeysService) { }

    getDTColumns(): any { } // For Data Table

    getDTOrder(): any { } // For Data Table

    getPMContent(reportList, branchName?, generatedBy?, totalValues?): any { } // For PDF Maker

    getBarCodeContent(reportDatas): any { } // For PDF Maker

    getAJHeader(docDefinition: any) {
        const styles = {
            title: {
                fontSize: 13,
                bold: true,
                color: '#2f308f',
                margin: [0, 0, 0, 5],
                alignment: 'center'
            },
            unit: {
                alignment: 'center',
                margin: [0, 0, 0, 5],
                color: '#09080d',
                bold: true,
            },
            addr: {
                alignment: 'center'
            },
            logo: {

            },
            lab: {
                corner: 5
            },
            gmail: {
                color: '#d70a8b',
                margin: [0, 20, 0, 0],
            },
            labNo: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 15, 0],
                color: '#d70a8b',
            }
        };

        docDefinition['content'].unshift(
            {
                layout: 'noBorders',
                style: 'tableExample',
                table: {
                    widths: ['15%', '70%', '15%'],
                    body: [
                        [
                            {
                                image: base64Img.ajLogo,
                                width: 80,
                                style: 'logo'
                            },
                            [
                                { text: 'GRAHAM CLINICAL LABORATORY & DIGITAL X-RAY\'S', style: 'title' },
                                { text: '(A Unit of GRAHAM CLINIC)', style: 'unit' },
                                { text: '134, North car street, SIVAKASI - 626 123.', style: 'addr' },
                            ],
                            {
                                image: base64Img.ajLab,
                                width: 65,
                                style: 'lab',
                            },
                        ],
                    ]
                },
            },
            {
                layout: 'noBorders',
                style: 'tableExample',
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            {
                                text: 'Gmail : grahamlab@yahoo.com', style: 'gmail'
                            },
                            {
                                text: 'Lab No.: 222226 \n\n Hos No: 222366', style: 'labNo',
                            },
                        ],
                    ]
                },
            },
            {
                stack: [
                    {
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: 555, y2: 5, lineWidth: 2, lineColor: '#2f308f' }],
                    },
                ],
            },
        );
        docDefinition['styles'] = { ...docDefinition['styles'], ...styles };
    }

    async printReport(reportList, docDefinition, branchName?, generatedBy?, totalValues?) {
        const curOrgId = this.globalHelper.getCurData('curOrgId'), ajHospitalOrgId = this.globalHelper.getConstantsValue('app').ajHospitalOrgId;
        if (docDefinition.iconFonts) {
            const curURL = window.location.origin;
            pdfMake.fonts = {
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-Italic.ttf'
                },
                TeXGyreBonum: {
                    normal: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Regular.ttf`,
                    bold: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Bold.ttf`,
                    italics: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Italic.ttf`,
                    bolditalics: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-BoldItalic.ttf`,
                },
                FontAwesome: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                },
                FaBrand: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                },
                FaRegular: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                },
                Fasolid: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                }
            };
        }
        this.docDefinition = docDefinition;
        this.docDefinition.images = {};
        if (branchName && generatedBy && totalValues) {
            this.docDefinition['content'] = await this.getPMContent(reportList, branchName, generatedBy, totalValues);
        } else if (reportList && reportList.patientBarOnly) {
            this.docDefinition['content'] = await this.getBarCodeContent(reportList);
        } else {
            this.docDefinition['content'] = await this.getPMContent(reportList);
        }
        const enabledHeaderFooter = (reportList.patientRequestPrintSetting) ? reportList.patientRequestPrintSetting.show_header_footer : 0;
        if (enabledHeaderFooter && curOrgId === ajHospitalOrgId) {
            await this.getAJHeader(this.docDefinition);
        }

        const pdf_document = pdfMake.createPdf(this.docDefinition);
        if (Object.keys(pdf_document).length > 0) {
            pdf_document.print();
        }
        return true;
    }

    getPDFPages(docDefinition) {
        const pdf_document = pdfMake.createPdf(docDefinition);
        return new Promise<any[]>((res) => {
            pdf_document._getPages(undefined, res);
        });
    }

    getDatatable(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.modelPath}/dt`, bodyDatas || {}, options);
    }

    getServerDTOptions(optionParams?, oWDTParams?) {
        const self = this,
            order = this.globalHelper.getObjectValue(this.getDTOrder(), 'length', false)
                ? this.getDTOrder() : [[0, 'asc']];

        return {
            ajax: (dtParams: any, callback) => {
                dtParams = { ...dtParams, ...oWDTParams };
                // this usage of jquery angular datatable loading time to disable inner event of table.
                $('.table').addClass('disable-dt-click');
                self.getDatatable(dtParams, optionParams).subscribe((resp: any) => {
                    $('.table').removeClass('disable-dt-click');
                    callback(resp);
                }, error => $('.table').removeClass('disable-dt-click'));
            },
            columns: self.getDTColumns(),
            order,
        };
    }

    getModelLists(optionParams?: object) {
        return this.httpService.getMethod(`${this.modelPath}/lists`, optionParams);
    }

    createModel(bodyDatas, optionParams?) {
        return this.httpService.postMethod(this.modelPath, bodyDatas, optionParams);
    }

    getModel(modelPk?, optionParams?) {
        const reqPath = `${this.modelPath}${modelPk ? `/${modelPk}` : ``}`;
        return this.httpService.getMethod(reqPath, optionParams);
    }

    updateModel(id, bodyDatas) {
        return this.httpService.putMethod(`${this.modelPath}/${id}`, bodyDatas);
    }

    updateModelColumn(id, bodyDatas) {
        return this.httpService.putMethod(`${this.modelPath}/${id}/updatecolumn`, bodyDatas);
    }

    deleteModel(id, optionParams?) {
        return this.httpService.deleteMethod(`${this.modelPath}/${id}`, optionParams);
    }

    getReportDatatable(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.modelPath}`, bodyDatas || {}, options);
    }

    getReportDTOptions(optionParams?, oWDTParams?) {
        const self = this;
        return {
            ajax: (dtParams: any, callback) => {
                dtParams = { ...dtParams, ...oWDTParams };
                $('.export_button').hide();
                self.getReportDatatable(dtParams, optionParams).subscribe((resp: any) => {
                    if (resp.data.length) {
                        $('.export_button').show();
                    } else if (document.getElementsByClassName('export_button').item(0)) {
                        document.getElementsByClassName('export_button').item(0).remove();
                    }
                    callback(resp);
                });
            },
            columns: self.getDTColumns()
        };
    }

    getAllActiveTenant() {
        return this.httpService.getMethod('shared/tenants');
    }

    getUserTenants() {
        return this.httpService.getMethod('default/user_tenants');
    }

    switchUserBranch(bodyDatas) {
        return this.httpService.postMethod('default/switch_branch', bodyDatas);
    }

    getServerTime() {
        return this.httpService.getMethod('default/server_time');
    }

    async baseHotKeysSet(pageInfo?) {
        if (this.timeout) { clearTimeout(this.timeout); }
        const self = this;
        this.timeout = setTimeout(async () => {
            self.removehotKey();
            let submitEle, printSubmitEle, cancelEle;
            const element = document.getElementsByClassName('dataTables_wrapper').item(0) ? document.getElementsByClassName('dataTables_wrapper').item(0).getElementsByTagName('input').item(0) : undefined,
                formEle = document.querySelector('form') ? document.querySelector('form').getElementsByTagName('button') : undefined;
            if (formEle) {
                for (let i = 0; i < formEle.length; i++) {
                    const btnTxt = formEle.item(i).textContent.toLowerCase().trim();
                    if (btnTxt === 'save' || btnTxt === 'submit') {
                        submitEle = formEle.item(i);
                    }
                    if (btnTxt.indexOf('print') !== -1) {
                        printSubmitEle = formEle.item(i);
                    }
                    if (btnTxt.indexOf('cancel') !== -1) {
                        cancelEle = formEle.item(i);
                    }
                }
            }
            if (self && pageInfo.buttonDatas && typeof pageInfo.buttonDatas === 'object') {
                if (pageInfo['buttonDatas']['text'].toLowerCase().trim().indexOf('add') === -1) {
                    await self.hotkeysService.add(new Hotkey('f9', (event: KeyboardEvent): boolean => {
                        self.router.navigate([pageInfo.buttonDatas.redUrl]);
                        return false;
                    }, ['INPUT', 'SELECT', 'TEXTAREA'], pageInfo['buttonDatas']['text']));
                    if (!cancelEle) {
                        await self.hotkeysService.add(new Hotkey('f8', (event: KeyboardEvent): boolean => {
                            self.router.navigate([pageInfo.buttonDatas.redUrl]);
                            return false;
                        }, undefined, 'Cancel'));
                    }
                } else {
                    await self.hotkeysService.add(new Hotkey('f5', (event: KeyboardEvent): boolean => {
                        self.router.navigate([pageInfo.buttonDatas.redUrl]);
                        return false;
                    }, ['INPUT', 'SELECT', 'TEXTAREA'], pageInfo['buttonDatas']['text']));
                }
            }
            if (element) {
                await self.hotkeysService.add(new Hotkey('s', (event: KeyboardEvent): boolean => {
                    element.focus();
                    return false;
                }, undefined, 'Search'));
            }
            if (submitEle) {
                await self.hotkeysService.add(new Hotkey('f6', (event: KeyboardEvent): boolean => {
                    submitEle.click();
                    return false;
                }, ['INPUT', 'SELECT', 'TEXTAREA'], 'Save'));
            }
            if (printSubmitEle) {
                await self.hotkeysService.add(new Hotkey('ctrl+p', (event: KeyboardEvent): boolean => {
                    printSubmitEle.click();
                    return false;
                }, ['INPUT', 'SELECT', 'TEXTAREA'], 'Print & Save'));
            }
            if (cancelEle) {
                await self.hotkeysService.add(new Hotkey('f8', (event: KeyboardEvent): boolean => {
                    cancelEle.click();
                    return false;
                }, undefined, 'Cancel'));
            }
        }, 200);
    }

    addHotKey(keyName, keyTitle, elmtRef, workElmtArr) {
        this.hotkeysService.add(new Hotkey(keyName, (event: KeyboardEvent): boolean => {
            elmtRef.click();
            return false;
        }, workElmtArr, keyTitle));
    }

    removehotKey() {
        this.hotkeysService.hotkeys = [this.hotkeysService.hotkeys[0]];
    }

    getExcelExport(tabless: string[], wsnames?: string[], wbname?: string, appname?: string) {
      const workbook = XLSX.utils.book_new();

      // Generate workbook sheet
      for (const [tableIndex, tableId] of Array.from(tabless.entries())) {

        // Get the worksheet name
        const worksheetName = (wsnames[tableIndex] || 'Sheet' + tableIndex).substr(0, 28);

        // Get the element with the id. It could contain more than one table.
        const tableGroup = document.getElementById(tableId);

        // If there are no tables, then no need to write anything to the workbook.
        if (!tableGroup) { continue; }

        // Get all the subtables
        const subtables = tableGroup.getElementsByTagName('table');

        // Create an empty sheet
        let sheet;

        // All the subtables are clubbed into the same sheet
        for (const [subtableIndex, subtable] of Array.from(Array.from(subtables).entries())) {
          const sheetData = [];

          for (const [rowIndex, row] of Array.from(Array.from(subtable.rows).entries())) {
            const rowData = [];

            for (const [cellIndex, cell] of Array.from(Array.from(row.cells).entries())) {
              // Get cell's inner text
              const cellValue = cell.innerText;
              rowData.push(cellValue);
            }

            sheetData.push(rowData);
          }

          // Append the cell data to the sheet
          if (!sheet) {
            sheet = XLSX.utils.aoa_to_sheet(sheetData);
          } else {
            XLSX.utils.sheet_add_aoa(sheet, sheetData, {origin: -1});
          }
        }

        // Sheets are then writtened into the workbook
        if (sheet) {
          XLSX.utils.book_append_sheet(workbook, sheet, worksheetName, true);
        }
      }

      let fileName = wbname || 'Workbook.xlsx';

      // If file name ends with .xls then replace it with .xlsx.
      fileName = fileName.replace(/\.xls$/g, '.xlsx');

      // Creates the file and initiates the download
      XLSX.writeFile(workbook, fileName);
    }
}
