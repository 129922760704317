import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { NgbModalRef, NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { DoctorScheduleService } from 'src/app/modules/configuration/doctor-schedule/services/doctor-schedule/doctor-schedule.service';
import { UserService } from 'src/app/modules/configuration/users/services/user.service';
import { PatientCategoryService } from 'src/app/modules/configuration/patient-categories/services/patient-category.service';
import { OutPatientService } from 'src/app/modules/out-patients/services/out-patient.service';
import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
    selector: 'app-reschedule-appoinment',
    templateUrl: './reschedule-appoinment.component.html',
    styleUrls: ['./reschedule-appoinment.component.css'],
    providers: [
        DoctorScheduleService,
        UserService,
        PatientCategoryService,
        OutPatientService
    ]
})
export class RescheduleAppoinmentComponent implements OnInit {

    @Input('options') options;
    @Output() modalSaveCB = new EventEmitter<string>();
    @ViewChild('rescheduleEleRef') rescheduleEleRef: NgbModalRef;

    submitSub: Subscription;
    modelRef;
    serverDate;
    err: any = [];
    model: any = {};
    docTdSchds = [];
    allConsultant = [];
    schdTimeLoading = false;
    formLoading = false;
    multiSelect: any = {};
    minDate = this.globalHelper.owlMinDate('current');

    constructor(private modalService: NgbModal, private globalHelper: GlobalHelper, private viewContainerRef: ViewContainerRef,
        private doctorScheduleService: DoctorScheduleService, private userService: UserService,
        private modelService: OutPatientService) { }

    ngOnInit() {
        this.model.status_date = this.minDate;
        const self = this;
        setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true,
                windowClass: 'modal-with-date-picker',
                backdropClass: 'modal-with-date-picker'
            };
            self.model.appointments = self.options.consultantDatas;
            self.model.consultant_id = self.options.consultantDatas[0].consultant_id;
            self.modelRef = self.modalService.open(self.rescheduleEleRef, option);
            self.schdTimeLoading = true;
            self.getDoctorSchedules();
            self.modelRef.result.then((result) => { }, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().rsaShowModal = false;
                }
            });
        });
        this.getMasterRecords();
    }

    getParentComponent() {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
    }

    closeModal() {
        this.getParentComponent().rsaShowModal = false;
        this.modelRef.close();
    }

    saveCallback() {
        this.modalSaveCB.next(this.model.status_date);
        this.closeModal();
    }

    getDoctorSchedules() {
        if (this.model.appointments[0].consultant_id) {
            const self = this,
            paramsOptions = this.globalHelper.convertObjectToParams(
                {
                    schedule_date: this.globalHelper.getMomentDatas('YYYY-MM-DD', this.model.status_date),
                    user_id: this.model.consultant_id
                }
            );
            this.multiSelect.schedule = false;
            self.doctorScheduleService.getModelLists({ params: paramsOptions }).subscribe((results: any) => {
                self.docTdSchds = Object.values(results.timerange);
                self.model.appointments.map(apptData => {
                    if (self.docTdSchds && self.docTdSchds.length) {
                        apptData.status_time = self.docTdSchds
                            .some(dstData => dstData.time === apptData.status_time) ? apptData.status_time : '';
                    } else {
                        apptData.status_time = '';
                    }
                });
                self.multiSelect.schedule = true;
                self.schdTimeLoading = false;
            });
        }
    }

    rescheduleAppointment() {
        const self = this;
        if (this.submitSub) {
            this.submitSub.unsubscribe();
        }
        if (this.model.consultant_id) {
            const curDocDatas = this.allConsultant.find(docDatas => docDatas.user_id === self.model.consultant_id);
            if (curDocDatas) {
                this.model.consultant_name = curDocDatas.fullname;
            }
        }
        const reqDatas = JSON.parse(JSON.stringify(this.model));
        reqDatas.status_date = this.globalHelper.getMomentDatas('YYYY-MM-DD', this.model.status_date);
        this.formLoading = true;
        this.submitSub = this.modelService.reschduleAppt(reqDatas).subscribe((results: any) => {
            self.submitSub = null;
            if (results && Array.isArray(results.appointment) && results.appointment.length && self.getParentComponent().activeTab === 'current') {
                results.appointment.map((apptDatas, apptIndex) => {
                    const removeAppt = reqDatas.appointments[apptIndex];
                    removeAppt['status_count'] = results.cancel_status_count;
                    removeAppt['status_time'] = self.globalHelper.getServerDateTime('HH:mm:ss');
                    apptDatas['status_count'] = results.status_count;
                });
            }
            self.formLoading = false;
            self.modalSaveCB.next(this.model.status_date);
            self.closeModal();
        }, error => {
            self.submitSub = null;
            self.formLoading = false;
        });
    }

    getMasterRecords() {
        const self = this,
        paramsOptions = this.globalHelper.convertObjectToParams({ where: 'allow_encounter:1,care_provider:1' });
        this.userService.getModelLists({ params: paramsOptions }).subscribe((results: any) => {
            self.allConsultant = results;
            self.multiSelect.consultant = true;
        }, error => self.multiSelect.consultant = true);
        this.modelService.getServerTime().subscribe((results: any) => {
            self.serverDate = self.globalHelper.getMomentDatas('YYYY-MM-DD', results.current_date);
        });
    }
}
