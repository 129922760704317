import { ElementRef, Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-inner-header-title',
  templateUrl: './inner-header-title.component.html',
  styleUrls: ['./inner-header-title.component.css'],
  providers: [],
})
export class InnerHeaderTitleComponent implements OnInit, OnChanges {
  @Input() viewPatientAccess;
  @Input() patientGUID;
  @Input() patientFullName;
  @Input() patientGlobalIntCode;

  previousPatientName = null;
  showTooltip = false;

  constructor(private element: ElementRef) {}

  ngOnInit() {}
  ngOnChanges() {
    const parent = this.element && this.element.nativeElement;
    if (!parent) { return; }
    const elem = parent.querySelector('.patient-name');
    if (!elem) { return; }

    if (this.previousPatientName === this.patientFullName) {
      return;
    }
    this.previousPatientName = this.patientFullName;

    const self = this;
    setTimeout(
      (() => {
        const isEllipsesActive = elem.offsetWidth < elem.scrollWidth;
        self.showTooltip = isEllipsesActive;
      }),
      0,
    );
  }
}
