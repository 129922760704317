import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { THEME_OPTIONS } from '../../constants/theme-options';
import { GlobalHelper } from '../../helper/global.helper';
import { HttpTrackingService } from 'src/app/services/http/httpTracking.service';

@Component({
    selector: 'app-full-width-left',
    templateUrl: './full-width-left.component.html',
    styleUrls: ['./full-width-left.component.css']
})
export class FullWidthLeftComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public globalHelper: GlobalHelper, public router: Router, private trackingService: HttpTrackingService) { }

  public showMobileMenu = false;
  public innerWidth: any;
  public defaultSidebar: any;
  options: any = {};
  isRequestPending = false;
  isLoadingNewModule = false;
  private routerSubscription: Subscription;

  ngOnInit() {
    this.options = JSON.parse(JSON.stringify(THEME_OPTIONS));
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    const self = this;
    this.routerSubscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        await self.handleNavigationStart();
      }
      if (event instanceof NavigationEnd && self.isRequestPending) {
        self.isLoadingNewModule = false;
      }
    });
  }

  async handleNavigationStart() {
    this.isRequestPending = this.trackingService.isPendingRequestsAvailable();
    if (this.isRequestPending) {
      this.trackingService.resetPendingStatus();
      const shouldLeave = await this.globalHelper.openConfirmationDialog(
        'Are you trying to leave the page?\n\n' +
        'The request has been cancelled. But the data might be saved partially and it leads to data corruption.'
      );
      if (shouldLeave) {
        this.isLoadingNewModule = true;
        this.trackingService.cancelPendingRequests();
      } else {
        this.router.navigate([this.router.url], { skipLocationChange: true });
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;
      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;
      default:
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
