import { Injectable } from '@angular/core';

import { BaseService } from '../../../services/base/base.service';

@Injectable()
export class PatientService extends BaseService {

    public modelPath = 'patient/patients';
    public commonSkeletonStyle = { style: { width: '80px', height: '15px', margin: '0px'} };
    private ahanaOrgId = this.globalHelper.getConstantsValue('app').ahanaOrgId;

    getPatientByGuid (optionParams) {
        return this.httpService.postMethod(`${this.modelPath}/get_patient_byguid`, optionParams);
    }

    getPatientActiveEncounter (bodyDatas) {
        return this.httpService.postMethod('patient/encounters/patient_have_active_encounter', bodyDatas);
    }

    getLetestInActiveEncounters (optionParams) {
        return this.httpService.getMethod('patient/encounters/get_latest_inactive_encounter', optionParams);
    }

    getEncountersByPatient (optionParams) {
        return this.httpService.getMethod('patient/encounters/get_encounter_list_by_patient?patient_guid=' + optionParams);
    }

    getPreviousNextPatient (bodyDatas) {
        return this.httpService.postMethod('patient/encounters/get_previous_next_patient', bodyDatas);
    }

    getPatientSearch (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_patient_search`, optionParams);
    }

    getPatientTenantDetails (patient_global_guid, orgId?) {
        let url = `${this.modelPath}/get_patient_tenant_details?patient_global_guid=${patient_global_guid}`;

        if (orgId !== this.ahanaOrgId) {
            url += `&org_id=${orgId}`;
        }

        return this.httpService.getMethod(url).toPromise();
    }

    getPatGroupByPat (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_patient_groupby_patient`, optionParams);
    }

    importPatient (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/import_patient`, bodyDatas);
    }

    patImportNewBranch (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/branch_import_patient`, bodyDatas);
    }

    uploadPatientImg (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/upload_image`, bodyDatas);
    }

    deletePatientImg (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/delete_image`, bodyDatas);
    }

    getPatDetails (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_patient_details`, optionParams);
    }

    getPatientDetails (bodyData) {
        return new Promise((res, rej) => {
            this.httpService.postMethod(`${this.modelPath}/get_hms_patient_details`, bodyData).subscribe(result => {
                res(result);
            }, error => {
                rej(error);
            });
        });
    }
}
