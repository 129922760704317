import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-basic-modal',
    templateUrl: './basic-modal.component.html',
    styleUrls: ['./basic-modal.component.css']
})
export class BasicModalComponent implements OnInit {

    @ViewChild('modalTmpRef') modalTmpRef: NgbModalRef;
    @Input('options') options;
    @Output() modalSaveCB = new EventEmitter<string>();
    @Output() modalCloseCB = new EventEmitter<void>();

    constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        const defaultOptions = {
            footer: true,
            cancelTxt: 'Cancel',
            saveTxt: 'Save',
            backdrop: true
        }, self = this;
        this.options = { ...defaultOptions, ...this.options };
        setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true,
                backdrop: this.options.backdrop
            };
            self.options.modalRef = self.modalService.open(self.modalTmpRef, option);
            self.options.modalRef.result.then((result) => {}, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().showModal = false;
                }
            });
        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

    saveCallback () {
        if (this.options.loading === undefined) {
            this.modalSaveCB.next(this.options.modalDatas);
            this.closeModal();
        } else {
            this.modalSaveCB.next(this.options);
        }
    }

    closeModal () {
        this.getParentComponent().showModal = false;
        this.options.modalRef.close();
        this.modalCloseCB.emit();
    }
}
