import { Injectable } from '@angular/core';

import { PharmacyService } from '../../../services/pharmacy.service';

@Injectable()
export class SaleService extends PharmacyService {

    public modelPath = 'pharmacy/sales';
    public readonly SCOPE_CASH = 'cash';
    public readonly SCOPE_CREDIT = 'credit';
    public readonly SCOPE_CASHONDELIVERY = 'cashondelivery';
    private ahanaOrgId = this.globalHelper.getConstantsValue('app').ahanaOrgId;

    getBodyDatas () {
        return {
            columns: [{
                data: 'sale_id',
                name: 'sale_id',
                searchable: false,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'patient_name',
                name: 'patient_name',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'encounter_id',
                name: 'encounter_id',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_bill_amount',
                name: 'total_bill_amount',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_paid_amount',
                name: 'total_paid_amount',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 0,
                    dir: 'desc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getPendingSaleBills (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_pending_sale_bill`, optionParams);
    }

    getSelectedPatientPrescription (patientGuid, encounterId, orgId) {
        let url = `patient/prescriptions/get_sale_prescription?patient_guid=${patientGuid}&encounter_id=${encounterId}`;

        if (orgId !== this.ahanaOrgId) {
            url += `&org_id=${orgId}`;
        }

        return this.httpService.getMethod(url);
    }

    getLatestSalePatwise (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_latest_sale_patient_wise`, optionParams);
    }

    getPatientWiseSalesDetails (optionParams) {
        return new Promise((res, rej) => {
            this.httpService.getMethod(`${this.modelPath}/get_patientwise_sale`, optionParams).subscribe(result => {
                res(result);
            }, error => {
                rej(error);
            });
        });
    }

    getsalesPendingPaymt (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_sale_pending_payment_amount`, optionParams);
    }

    getAllowIPSaleCash (optionParams?) {
        return this.httpService.getMethod(`${this.modelPath}/get_allow_ip_patient_sale_cash`, optionParams);
    }

    printHeader() {
        return {
            text: '',
            margin: 0,
            alignment: 'center'
        };
    }

    printFooter(sale) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app'),
            companyTxt = orgId === ajHospitalOrgId
                ? 'Powered By Sumanas Technologies' : 'Software designed by www.sumanastech.com',
            print_setting = this.globalHelper.getObjectValue(sale, 'salePrintSetting', {}),
            footer = print_setting.footer ? (print_setting.footer_text + `\n${companyTxt}`) : '';

        return {
            text: footer,
            fontSize: 7,
            margin: 0,
            alignment: 'center'
        };
    }

    printStyle() {
        return {
            h1: {
                fontSize: 11,
                bold: true,
            },
            h2: {
                fontSize: 8,
                bold: true,
            },
            th: {
                fontSize: 8,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 7,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 8,
            },
            total: {
                fontSize: 10,
                bold: true,
                margin: [5, 0, 5, 3]
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 0, 5, 3]
            }
        };
    }

    async getPMContent(sale) {
        let content = this.globalHelper.getObjectValue(sale, 'saleData.saleReturnOnly', false)
            ? [] : await this.getSalePrintContent(sale, true);

        if (sale.saleReturnDatas && sale.saleReturnDatas.length) {
            const saleReturnContent = await this.getSaleReturnPrintContent(sale, true);
            if (content.length) {
                content.push({text: '', pageBreak: 'before'});
            }
            content = content.concat(saleReturnContent);
        }

        return content;
    }

    async getAllSaleContent(printDatas) {
        const perPageInfo = [], print_setting = printDatas.salePrintSetting ? printDatas.salePrintSetting : {}, bar_img = '';
        let logo, payment;
        const
            perPageItems = [],
            patientAddress = printDatas.patient_address || printDatas.patientAddress,
            dl_nos = print_setting.dl_nos ? `DL.NO.: ` + print_setting.dl_no_text : '',
            gst_no = print_setting.gst_no ? `GSTIN: ` + print_setting.gst_no_text : '';

        perPageInfo.push({ text: 'ARUN KUMAR MEDICALS', fontSize: 14, bold: true, alignment: 'center' });
        perPageInfo.push({ text: '(A Unit of GRAHAM CLINIC)', fontSize: 12, alignment: 'center', italics: false });

        if (print_setting.logoUrl) {
            logo = [{
                columns: [{
                    image: 'data:image/jpeg;base64,' + print_setting.logoUrl,
                    height: 20,
                    width: 100,
                    margin: '',
                }, {
                    text: `(${printDatas['branch_address']})`,
                    margin: [0, 5, 0, 0],
                    fontSize: 8
                }
                ]
            }];
        } else {
            logo = [{ text: '' }];
        }

        const header = [[{
            colSpan: 2,
            text: gst_no,
            fontSize: 7
        }, {}, {
            colSpan: 4,
            text: '21, P.S.R. Road, SIVAKASI - 626123',
            fontSize: 10,
            italics: false
        }, {}, {}, {}, {
            text: dl_nos,
            fontSize: 7
        }]];

        perPageInfo.push({
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                body: header,
            }
        });

        if (printDatas['payment_type'] === 'CA') {
            payment = 'Cash';
        }
        if (printDatas['payment_type'] === 'CR') {
            payment = 'Credit';
        }
        if (printDatas['payment_type'] === 'COD') {
            payment = 'Cash On Delivery';
        }

        perPageItems.push([{
            border: [false, true, false, true],
            rowspan: 4,
            text: 'S.No',
            style: 'th'
        }, {
            border: [false, true, false, true],
            rowspan: 2,
            text: 'Date',
            style: 'th'
       }, {
            border: [false, true, false, true],
            rowspan: 2,
            text: 'Medical Bill No',
            style: 'th'
        }, {
            border: [false, true, false, true],
            rowspan: 2,
            text: 'Amount',
            style: 'th'
        }], [{
            colSpan: 4,
            border: [false, false, false, false],
            text: ''
        }, {}, {}, {}]);

        if (printDatas.sales.length) {
            printDatas.sales.map((x, i) => {
                perPageItems.push(
                    [{
                        border: [false, false, false, true],
                        text: i + 1
                    }, {
                        border: [false, false, false, true],
                        text: this.globalHelper.getMomentDatas('DD-MM-YYYY', x.sale_date)
                    }, {
                        border: [false, false, false, true],
                        text: x.bill_no
                    }, {
                        border: [false, false, false, true],
                        text: x.bill_amount
                    }],
                );
            });
        }

        const { patientTitle, patientName } = this.globalHelper.getPatientTitleAndName(printDatas['patient_name']);
        perPageInfo.push({
            layout: 'Borders',
            table: {
                widths: ['auto', '*'],
                body: [[{
                    border: [false, true, false, false],
                    layout: {
                        paddingLeft: function (idx, node) {
                            return 0;
                        },
                        paddingRight: function (idx, node) {
                            return 2;
                        },
                        paddingTop: function (idx, node) {
                            return 0;
                        },
                        paddingBottom: function (idx, node) {
                            return 0;
                        },
                    },
                    table: {
                        widths: ['auto', 5, 'auto'],
                        body: [[{
                            border: [false, false, false, false],
                            text: 'Date',
                            style: 'h2',
                            margin: [-5, 0, 0, 0]
                        }, {
                            border: [false, false, false, false],
                            text: ':',
                            style: 'h2'
                        }, {
                            border: [false, false, false, false],
                            text: this.globalHelper.getMomentDatas('DD-MM-YYYY hh:mm A'),
                            style: 'normaltxt'
                        }], [{
                            border: [false, false, false, false],
                            text: 'Patient',
                            style: 'h2',
                            margin: [-5, 0, 0, 0]
                        },
                        {
                            text: ':',
                            border: [false, false, false, false],
                            style: 'h2'
                        },
                        {
                            border: [false, false, false, false],
                            text: `${patientTitle}${patientName}`,
                            style: 'normaltxt'
                        }
                        ]]
                    },
                },
                {
                    border: [false, true, false, false],
                    layout: {
                        paddingLeft: function (idx, node) {
                            return 0;
                        },
                        paddingRight: function (idx, node) {
                            return 2;
                        },
                        paddingTop: function (idx, node) {
                            return 0;
                        },
                        paddingBottom: function (idx, node) {
                            return 0;
                        },
                    },
                    table: {
                        widths: ['auto', 5, 70, 25, 5, 100, 25, 5, '*'],
                        body: [[{
                            border: [false, false, false, false],
                            text: 'UHID',
                            style: 'h2',
                            margin: [-5, 0, 0, 0]
                        }, {
                            text: ':',
                            border: [false, false, false, false],
                            style: 'h2'
                        }, {
                            border: [false, false, false, false],
                            text: printDatas['uhid'] || '-',
                            style: 'normaltxt'
                        }, {
                            border: [false, false, false, false],
                            text: 'Doctor',
                            style: 'h2',
                            margin: [-5, 0, 0, 0]
                        }, {
                            text: ':',
                            border: [false, false, false, false],
                            style: 'h2'
                        }, {
                            border: [false, false, false, false],
                            text: printDatas['consultant_name'] || '-',
                            style: 'normaltxt'
                        }, {
                            border: [false, false, false, false],
                            text: 'Address',
                            style: 'h2',
                            margin: [-5, 0, 0, 0]
                        }, {
                            text: ':',
                            border: [false, false, false, false],
                            style: 'h2'
                        }, {
                            border: [false, false, false, false],
                            text: this.globalHelper.toTitleCase((patientAddress && patientAddress.addr_perm_address) ? patientAddress.addr_perm_address.replace(/\n/ig, ' ') : ' - '),
                            style: 'normaltxt'
                        }]]
                    }
                }]]
            },
        }, {
            layout: {
                hLineWidth: function (idx, node) {
                    return (idx === 0) ? 2 : 1;
                }
            },
            table: {
                headerRows: 1,
                widths: [130, 130, 130, 130],
                body: perPageItems,
            },
        });

        return perPageInfo;
    }

    async getPatientLatestSale (openSale, model, laststBillLoading, billModel?) {
        if (openSale) {
            laststBillLoading.value = true;
            const self = this,
                paramsOptions = this.globalHelper.convertObjectToParams({
                    patient_id: model.patient_id,
                    ...(model.org_id !== this.ahanaOrgId && { org_id: model.org_id })
                });

            await this.getLatestSalePatwise({params: paramsOptions}).subscribe((result: any) => {
                result.sales.map((saleData: any) => {
                    saleData.bill_no_with_patient += ` (${self.globalHelper.getMomentDatas('app-date-time', saleData.sale_date)})`;
                });
                model.latst_sales = result.sales;
                laststBillLoading.value = false;
            }, error => {
                laststBillLoading.value = false;
            });
        } else {
            model.latst_sales = [];
            model.return_Bill = '';
            model.current_sale_return_data = {
                openNewRtnSale: false
            };
            model.all_sale_return_data = {
                total_item_vat_amount: '0.00',
                total_item_sale_amount: '0.00',
                total_item_discount_percent: 0,
                total_item_discount_amount: '0.00',
                total_item_amount: '0.00',
                roundoff_amount: '0.00',
                bill_amount: '0.00',
                return_items: []
            };
            if (model.indentReturn && model.indentReturn.length > 0) { model.indentReturn = []; }
            model.sale_return_data = [];
        }
        if (billModel) {
            billModel.billId = {};
        }
    }

    valueAssignedModel (model, saleDetails) {
        model.current_sale_return_data = saleDetails;
        if (model.current_sale_return_data.mobile_no === null) {
            // tslint:disable-next-line:no-unused-expression
            delete model.current_sale_return_data.mobile_no;
        }
        model.current_sale_return_data.product_items = model.current_sale_return_data.items.map((e) => {
            e.batch_no = e.batch.batch_no;
            e.bill_no = saleDetails.bill_no;
            e.batch_id = e.batch.batch_id;
            e.expiry_date = e.batch.expiry_date;
            e.sale_quantity = e.quantity;
            e.old_quantity = e.quantity;
            e.selected = false;
            e.quantity = 0;
            e.discount_percentage = e.discount_percentage;
            e.discount_amount = 0;
            e.taxable_value = 0;
            e.cgst_amount = 0;
            e.sgst_amount = 0;
            e.total_amount = 0;
            e.total_returned_quantity = parseInt(e.total_returned_quantity);
            return e;
        });
        // tslint:disable-next-line:no-unused-expression
        delete model.current_sale_return_data.items, model.current_sale_return_data.saleBilling, model.current_sale_return_data.patient_address, model.current_sale_return_data.salePrintSetting;
        model.current_sale_return_data['sale_return_date'] = this.globalHelper.getMomentDatas('YYYY-M-DD HH:mm:ss', model.sale_date);
        // model.sale_return_data.allowNewSale = false;
        model.current_sale_return_data.total_item_vat_amount = '0.00';
        model.current_sale_return_data.total_item_sale_amount = '0.00';
        model.current_sale_return_data.total_item_discount_percent = 0;
        model.current_sale_return_data.total_item_discount_amount = '0.00';
        model.current_sale_return_data.total_item_amount = '0.00';
        model.current_sale_return_data.roundoff_amount = '0.00';
        model.current_sale_return_data.bill_amount = '0.00';
        model.current_sale_return_data.openNewRtnSale = true;
        model.isRemoveSaleBill = true;
    }

}
