import { Directive, AfterViewInit, ElementRef, ContentChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[ellipsisActive]'
})
export class EllipsisActiveDirective implements AfterViewInit {

  @ContentChild(NgbTooltip) tooltip: NgbTooltip;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {

    setTimeout(() => {
      const element = this.elementRef.nativeElement;

      if (element.offsetWidth < element.scrollWidth) {

        // Add a class when the condition is true
        element.classList.add('cursor-pointer');

        // Disable tooltip by setting the property
        if (this.tooltip) {
          this.tooltip.disableTooltip = false;
          this.tooltip.placement = 'bottom-left';
        }
      }
    }, 500);
  }
}
