export const PATIENT_MODULE_ROUTES: any = {
    defaultRoutes: [
        'change-appointment-status'
    ],
    vitalRoutes: [
        'vitals',
        'create-vital',
        'update-vital'
    ],
    documentRoutes: [
        'documents',
        'view-case-history',
        'view-medical-case-history',
        'view-other-document',
        'view-psychological-assessment',
        'view-psychological-therapy',
        'create-case-history',
        'create-medical-case-history',
        'create-scanned-document',
        'create-other-document',
        'create-psychological-assessment',
        'create-psychological-therapy',
        'update-case-history',
        'update-medical-case-history',
        'update-other-document',
        'update-psychological-assessment',
        'update-psychological-therapy'
    ],
    noteRoutes: [
        'notes',
        'view-note',
        'create-note',
        'update-note'
    ],
    encounterRoutes: [
        'encounters',
        'appointment',
        'admission',
        'discharge',
        'transfer',
        'swapping',
        'modify-case-sheet-no',
        'update-admission',
        'edit-doctor-fee'
    ],
    procedureRoutes: [
        'procedures',
        'create-procedure',
        'update-procedure'
    ],
    consultantRoutes: [
        'consultant',
        'create-consultant',
        'update-consultant'
    ],
    billingRoutes: [
        'billing',
        'bill-details',
        'add-payment',
        'edit-payment',
        'add-room-concession-amount',
        'bill-discount',
        'edit-other-charges',
        'add-other-charges',
        'add-extra-amount',
        'edit-extra-amount',
        'add-concession-amount',
        'edit-concession-amount',
        'billing-history',
        'pharmacy-concession'
    ],
    requestAndResultRoutes: [
        'request-and-result',
        'update-patient-request',
    ]
};
