import { Component, OnInit, HostListener, ElementRef, Renderer2 } from '@angular/core';

import { GlobalHelper } from '../app/helper/global.helper';
import { NavigationLoggerService } from './navigation-logger.service';
import { HotjarService } from './hotjar.service';
import { EnvService } from './services/env/env.service';

declare global {
    interface Window {
        Pusher: any;
        hj: {
          (...args: any[]): void;
          q?: any[];
        };
        _hjSettings: { hjid: number; hjsv: number };
      }
}
declare var require: any;

window.Pusher = require('pusher-js');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    @HostListener('submit', ['$event'])onEvent(event) {
        const target = this.el.nativeElement.querySelector('.is-invalid');
        if (target) {
            $('html,body').animate({ scrollTop: $(target).offset().top - 100 }, 'slow');
            target.focus();
            // if (this.el.nativeElement.classList.contains('form-control')) {
            // 	target.focus();
            // } else {
            // 	// this.el.focusSearchInput();// For ng-select focus;
            // }
        }
    }

    constructor(private el: ElementRef, private globalHelper: GlobalHelper, private env: EnvService,
        private navigationLoggerService: NavigationLoggerService, private renderer: Renderer2, private hotjarService: HotjarService) {
        const self = this;
        window.addEventListener('online', () => {
            self.globalHelper.toastrClear();
        });
        window.addEventListener('offline', () => {
            self.globalHelper.toastrOpen('E', 'Network connection failed', {
                disableTimeOut: true,
                tapToDismiss: false
            });
        });
    }

    ngOnInit() {
        this.globalHelper.lsPush(this.globalHelper.getConstantsValue('app').appMaintanence, false);
        const self = this, ctrlKey = 17, sftKey = 16, rKey = 82;
        let ctrlDown = false, sftDown = false;
        if (this.env.onHotjar) {
            this.hotjarService.initHotjar(this.env.hotJarSiteID);
        }
        $(document).keydown((kdEvent) => {
            if (kdEvent.keyCode === ctrlKey) {
                ctrlDown = true;
            }
            if (kdEvent.keyCode === sftKey) {
                sftDown = true;
            }
            if (sftDown && ctrlDown && (kdEvent.keyCode === rKey)) {
                const newVersion = $('#version-value').text();
                self.globalHelper.lsPush(self.globalHelper.getConstantsValue('app').appVersion, newVersion);
                self.globalHelper.lsPush(self.globalHelper.getConstantsValue('app').versionUpdateSuccessMsg, true);
                // kdEvent.preventDefault();
            }
        }).keyup((e) => {
            if (e.keyCode === ctrlKey) {
                ctrlDown = false;
            }
            if (e.keyCode === sftKey) {
                sftDown = false;
            }
        });
        this.renderer.listen('window', 'click', (e: Event) => {
            self.globalHelper.toastrClear();
        });
    }
    versionUpdate () {
        const newVersion = $('#version-value').text();
        this.globalHelper.lsPush(this.globalHelper.getConstantsValue('app').appVersion, newVersion);
        this.globalHelper.lsPush(this.globalHelper.getConstantsValue('app').versionUpdateSuccessMsg, true);
        window.location.replace(location.href);
    }
}
